<template>
  <div class="dataManagement">
    <!-- 数据管理模块 -->
    <div v-for="item in tabList" :key="item.name" v-premiSub="item.premSub">
      <router-link v-if="item.to" :to="item.to" class="item">
        <img :src="item.icon" />
        <p>{{item.name}}</p>
      </router-link>
      <!-- <div v-else class="item" @click="getsamplingList()">
        <img :src="item.icon" />
        <p>{{item.name}}</p>
      </div> -->
    </div>
    <!-- 抽检设置弹窗 -->
    <el-dialog
      custom-class="samplingDialog dataM"
      title="抽检设置"
      :visible.sync="samplingVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      >
      <div class="samplingList">
        <div class="title">
          <div class="inspection">送检数量</div>
          <div class="sampling">抽检数量</div>
        </div>
        <div v-for="(item,index) in samplingList" :key="index">
          <el-form class="list" :model="item" :rules="rules" :ref="`form${index}`">
            <div class="inspectionValue">
              <el-form-item prop="inspectionQuantityStart">
                <el-input v-model.number="item.inspectionQuantityStart" @focus="formIndex=index"/>
              </el-form-item>
              <span class="jg"> - </span>
              <el-form-item prop="inspectionQuantityEnd">
                <el-input v-model.number="item.inspectionQuantityEnd" @focus="formIndex=index"/>
              </el-form-item>
            </div>
            <el-form-item prop="samplingQuantity">
              <el-input class="samplingValue" v-model.number="item.samplingQuantity" @focus="formIndex=index"/>
            </el-form-item>
          </el-form>
        </div>
        <div class="line"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="checkSampling">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getsampling,updateSampling} from '@/api/data.js'
export default {
  name: "dataManagement",
  data() {
    var checkNumber1 = (rule, value, callback) => {
      let endValue = this.samplingList[this.formIndex].inspectionQuantityEnd
      this.$refs[`form${this.formIndex}`][0].validateField('samplingQuantity')
      if(value < 0){
        callback(new Error('不能小于0'));
      }else if (value > endValue) {
        callback(new Error(`不能大于${endValue}`));
      } else {
        callback();
      }
    }
    var checkNumber2 = (rule, value, callback) => {
      let startValue = this.samplingList[this.formIndex].inspectionQuantityStart
      this.$refs[`form${this.formIndex}`][0].validateField('samplingQuantity')
      let endValue = this.samplingList[this.formIndex+1]?.inspectionQuantityStart
      if(value < 0){
        callback(new Error('不能小于0'));
      }else if (value < startValue) {
        callback(new Error(`不能小于${startValue}`));
      }else if(endValue && value>=endValue){
        callback(new Error('送检数量范围冲突'));
      } else {
        callback();
      }
    }
    var checkNumber3 = (rule, value, callback) => {
      // let startValue = this.samplingList[this.formIndex].inspectionQuantityStart
      let endValue = this.samplingList[this.formIndex].inspectionQuantityEnd
      if(value < 0){
        callback(new Error('不能小于0'));
      }else if (value > endValue) {
        callback(new Error('不能大于送检数量范围'));
      } else {
        callback();
      }
    }
    return {
      tabList:Object.freeze([
        {
          name:'工作日历',
          icon:require('@/assets/images/dataManage/workCalendar.png'),
          to:'workCalendar',
          premSub:'工作日历'
        },{
          name:'生产资源',
          icon:require('@/assets/images/dataManage/produceResource.png'),
          to: 'produceResource',
          premSub:'生产资源'
        },{
          name:'产品数据',
          icon:require('@/assets/images/dataManage/productData.png'),
          to: 'productData',
          premSub:'产品数据'
        },
        // {
        //   name:'BOM管理',
        //   icon:require('@/assets/images/dataManage/bomManagement.png'),
        //   to:'bomManagement'
        // },
        {
          name:'工步列表',
          icon:require('@/assets/images/dataManage/processManagement.png'),
          to:'processManagement',
          premSub:'产品数据'
        },
        {
          name:'客户管理',
          icon:require('@/assets/images/dataManage/customerManagement.png'),
          to:'customerManagement',
          premSub:'客户管理'
        },
        // {
        //   name:'库存管理',
        //   icon:require('@/assets/images/dataManage/inventoryManagement.png'),
        //   to:'inventoryManagement'
        // },
        {
          name:'抽检设置',
          icon: require('@/assets/images/dataManage/Sampling.png'),
          premSub:'抽检设置'
        }
      ]),
      samplingVisible:false,
      samplingList:[],
      formIndex:0,
      rules: {
        inspectionQuantityStart: [
          { required: true, message: '不能为空',trigger: ['blur','change']},
          { type: 'number', message: '请输入数值',trigger: ['blur','change']},
          { validator: checkNumber1, trigger: ['blur','change'] }
        ],
        inspectionQuantityEnd:[
          { required: true, message: '不能为空',trigger: ['blur','change']},
          { type: 'number', message: '请输入数值',trigger: ['blur','change']},
          { validator: checkNumber2, trigger: ['blur','change'] }
        ],
        samplingQuantity:[
          { required: true, message: '不能为空',trigger: ['blur','change']},
          { type: 'number', message: '请输入数值',trigger: ['blur','change']},
          { validator: checkNumber3, trigger: ['blur','change'] }
        ]
      }
    };
  },
  mounted(){
  },
  methods:{
    handleClose(){
      this.samplingVisible = false
    },
    checkSampling(){
      let result = document.getElementsByClassName('is-error')
      if(!result.length){
        this.setSampling()
      }else{
        this.$message.warning('请输入正确的数值')
      }
    },
    setSampling(){
      updateSampling(this.samplingList).then(res=>{
        if(res.code==0){
          this.samplingVisible = false
        }else{
          this.$message.error(res.msg || '接口错误')
        }
      })
    },
    getsamplingList(){
      getsampling().then(res=>{
        if(res.code==0){
          this.samplingList = res.data
          this.samplingVisible = true
        }else{
          this.$message.error(res.msg || '接口错误')
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.dataManagement{
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
  text-align: left;
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #232323;
    width: 400px;
    height: 124px;
    border-radius: 8px;
    padding: 30px;
    margin: 0 30px 30px 0;
    cursor: pointer;
    img {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
  }
  .samplingList{
    background: #1A1A1A;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-height: 500px;
    overflow-y:auto ;
    .line{
      position: absolute;
      right: 35%;
      top: 30px;
      height: 86%;
      width: 1px;
      background: #4F4F4F;
    }
    .title{
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 60px;
      margin-bottom: 10px;
    }
    .list{
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 60px;
      .inspectionValue{
        display: grid;
        grid-template-columns: 1fr 40px 1fr;
        .jg{
          text-align: center;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.samplingDialog{
  .el-input__inner{
    border-radius: 6px!important;
  }
  .el-form-item{
    margin-bottom: 18px;
    .el-form-item__error{
      font-size: 10px;
    }
  }
}
</style>
